<template>
<div class="ml-2 mt-2">
  <h3>Scheduled Function performance test</h3>
  <button class="btn btn-primary" @click="init">Init</button>
  <button class="btn btn-primary ml-2" @click="shutdown">Shutdown</button>
  <button class="btn btn-primary ml-2" @click="clear">Clear</button>
  <p>
  Obj: {{ manager }}<br/>
  </p>
  <div v-for="test in updates" v-bind:key="test.id">
    <p>
    {{ test.id }}<br/>
    Read: {{ test.endRead - test.startRead }}ms<br/>
    Write: {{ test.endWrite - test.startWrite }}ms<br/>
    Snapshot: {{ test.snapshotReceived - test.snapshotStarted }}ms<br/>
    </p>
  </div>
  <button class="btn btn-primary" @click="testsEmulatorScheduledEventUpdate">testsEmulatorScheduledEventUpdate</button>
  <h4 class="mt-4">Firestore</h4>
  <button class="btn btn-primary" @click="testsFirestorePerformanceNormal">testsFirestorePerformanceNormal</button>
  <button class="btn btn-primary ml-2" @click="testsFirestorePerformanceSlow">testsFirestorePerformanceSlow</button>
</div>
</template>

<script>
import { db } from "@/services/db";
import { deleteCollection } from "@/services/dbutils";
import { testsFirestorePerformanceNormal, 
  testsFirestorePerformanceSlow,
  testsEmulatorScheduledEventUpdate } from "@/services/functions";

export default {
  name: 'app',
  components: {
  },
  data() {
    return {
      manager: null,
      updates: {},
    };
  },
  mounted() {
    this.bind();
    this.$bind("updates", db.collection("Tests/manager/Updates"));
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      db.collection("Tests").doc("manager").set({"exists":true});
    },
    bind() {
      this.$bind("manager", db.collection("Tests").doc("manager"));
    },
    testsFirestorePerformanceNormal,
    testsFirestorePerformanceSlow,
    testsEmulatorScheduledEventUpdate,
    shutdown() {
      db.collection("Tests").doc("manager").update({"shutdown":true});
    },
    async clear() {
      deleteCollection(db.collection("Tests/manager/Updates"));
    }
  }
}
</script>

<style>

</style>